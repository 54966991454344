import React, { useState } from "react";
import Joi from "joi-browser";
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'


const PostReply = (props) => {
  const [data, setData] = useState({ comment: "" });
  const [errors, setErrors] = useState({ comment: "" });
  const [isReplySuccessful, setIsReplySuccessful] = useState(false);
  const [isReplyFailed, setIsReplyFailed] = useState(false);
  const username = useSelector(state => state.user.username);

  const schema = {
    comment: Joi.string().min(5).max(100).required().label("Comment"),
  };

  const navigate = useNavigate()
  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);

    const errorMessage = validateProperty(input);
    setErrors({ ...errors, [input.name]: errorMessage });
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaObj = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaObj);
    return error ? error.details[0].message : null;
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;
    const newErrors = {};
    for (let item of error.details) {
      newErrors[item.path[0]] = item.message;
    }
    return newErrors;
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const response = await axios.post(
        'https://m9a4v9uqck.execute-api.us-east-1.amazonaws.com/Test',
        {
          'Type': 1,
          'UserId': username,
          'QuestionId': props.id,
          'Content': data.comment,
          'Timestamp': Date.now()         
        },
        {
          headers: {
            Authorization: token,
            'Access-Control-Allow-Origin': '*',
          },
        }
      );
      console.log(response.data);
      console.log('posting reply Succeed');
      setIsReplySuccessful(true);
    } catch (ex) {
      setIsReplyFailed(true);
      console.error('Error posting reply:', ex);
    }
  };

  const handleCloseDialog = () => {
    setIsReplySuccessful(false);
    navigate('/Chat')
  };

  const handleReplyFailedDialog = () => {
    setIsReplyFailed(false);
  }

  return (
    <React.Fragment>
      <div className="container col-lg-6 shadow-lg p-3 mt-5 bg-body rounded">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="description" style={{display:'flex',fontSize:30,color:'white'}}>Question Reply:</label>
            <textarea
              className="border border-primary form-control"
              style={{ height:150,width:650,fontSize:30 }}
              value={data.comment}
              onChange={handleChange}
              name="comment"
              type="comment"
              id="comment"
            />
            <div style={{height:50}}>
              {errors.comment && (
                <div className="alert-info" style={{color:'red',fontSize:20,fontWeight:'bold'}}>{errors.comment}</div>
              )}
            </div>
            <div className="text-center">
              <button
                className="chatBlackButton"
                disabled={validate()}
              >
                Question Reply
              </button>
            </div>
          </div>
        </form>
      </div>
      {isReplySuccessful && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>Reply Succeed!</h2>
            <p className='popup-bodyText'>Navigate to All Questions.</p>
            <button className='amplify-button' style={{margin: 20}} onClick={handleCloseDialog}>OK</button>            
          </div>
        </div>
      )}
      {isReplyFailed && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>Reply Failed!</h2>
            <p className='popup-bodyText'>Something went wrong. Please wait to try again</p>
            <button className='amplify-button' style={{margin: 20}} onClick={handleReplyFailedDialog}>OK</button>            
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PostReply;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Posts from "../../Common/post";
import Pagination from "../../Common/pagination";
import { paginate } from "../../utils/paginate";
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { createNewPost } from "../../Redux/userSlice";

const LandscapeDashBoard = () => {
  const [allposts, setAllPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(4);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const response = await axios.get('https://m9a4v9uqck.execute-api.us-east-1.amazonaws.com/Test', {
        headers: {
          "Authorization": token,
          "Access-Control-Allow-Origin": '*',
        }
      });
      setAllPosts(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const extractNumberFromString = (str) => {
    const match = str.match(/\d+$/); // Regular expression to match one or more digits at the end of the string
    if (match) {
      return parseInt(match[0]); // Return the matched number
    } else {
      return null; // Return null if no number is found
    }
  }

  const findLargestNumber = (numbers) => {
    if (numbers.length === 0)
      return null;
    
    let largest = numbers[0]; // Assume the first number is the largest
  
    for (let i = 1; i < numbers.length; i++) {
      if (numbers[i] > largest) {
        largest = numbers[i]; // Update largest if a larger number is found
      }
    }
  
    return largest; // Return the largest number
  }

  const getPosts = () => {
    const filtered = [];
    for (let i in allposts) {
      const post = allposts[i]; 
      filtered.push(post);   
    }
    filtered.sort((a, b) => b.Timestamp - a.Timestamp);
    return filtered;
  };

  const handleNewPostQuesitonId = () => {
    const numArr = [];
    for (let i in allposts) {
      const post = allposts[i];
      const numberPart = extractNumberFromString(post.QuestionId);
      numArr.push(numberPart);
    }
    const newId = findLargestNumber(numArr) + 1
    //Increment for QuestionId
    const newQuestionId = `Question_Id${newId}`
    dispatch(createNewPost({newQuestionId}))
    console.log(newQuestionId);
  }

  const filtered = getPosts();
  const posts = paginate(filtered, currentPage, pageSize);

  return (
    <React.Fragment>
      {isLoading ? (
        <p style={{color:'#FFFFFF',fontSize:40,fontWeight:'bold'}}>Loading, Please wait...</p>
      ):(
      <div className="container">
        <div className="row">
          <div style={{ position: 'absolute', right: 100 }}>
            <Link to="/Product">
              <button type="button" className="chatExitButton" >
                Exit
              </button>
            </Link>
          </div>
          <div className="col">
            <div className="d-flex w-100 justify-content-between m-3">
              <h3 style={{ fontSize: 24, color: "white" }}>Showing {filtered.length} questions.</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <Posts posts={posts} />
          </div>
          <div className="col-3">
            <Link to='/NewPost'>
              <button
                type="button"
                className="chatBlackButton"
                style={{ margin: (0, 30) }}
                onClick={handleNewPostQuesitonId}
              >
                New Question
              </button>
            </Link>
          </div>
          <Pagination
            itemCount={filtered.length}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>)}
    </React.Fragment>
  );
}

export default LandscapeDashBoard;

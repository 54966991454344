import { Amplify, Storage } from 'aws-amplify'

export function configureAmplify() {
    Amplify.configure(
    {
     Auth: {
       identityPoolId: 'us-east-1:29caa40d-7e30-4926-b8e9-7677650b952a',
       region: 'us-east-1',
       userPoolId: 'us-east-1_4lhPVo0Qb',
       userPoolWebClientId: '6oahcsak4r99jf0867d07uccjv',
      },
      Storage: { 
       bucket: 'kixfull',
       region: 'us-east-1',
       identityPoolId: 'us-east-1:29caa40d-7e30-4926-b8e9-7677650b952a'
      }
    }
   );
  }

  //Configure Storage with S3 bucket information
export function SetS3Config(bucket, level){
    Storage.configure({ 
        bucket: bucket,
        level: level,
        region: 'us-east-1',  
        identityPoolId: 'us-east-1:29caa40d-7e30-4926-b8e9-7677650b952a'
    });
 }
import React, { useState, useEffect } from "react";
import { PersonCircle } from "react-bootstrap-icons";
import { ToastContainer } from "react-toastify";
import PostReply from "../../Common/createReply";
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";


const PostDetail = () => {
  const [post, setPost] = useState([]);
  const [replies, setReplies] = useState([]);
  const { QuestionId, UserId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();

        const queryByParamResponse = await axios.get(`https://m9a4v9uqck.execute-api.us-east-1.amazonaws.com/Test?userId=${UserId}&questionId=${QuestionId}`, {
          headers: {
            "Authorization": token,
            "Access-Control-Allow-Origin": '*',
          }
        });

        const content = queryByParamResponse.data.map(item => item.Content);
        const postData = content.flat().filter(
            item => item.Type === 0
          );
        const repliesData = content.flat().filter(
            item => item.Type === 1
          );
        setPost(postData)
        setReplies(repliesData)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [QuestionId, UserId]); // Dependencies array to watch for changes in route params


  const convertTimeFormat = (d) => {
    let year = d.getFullYear();
    let month = ("0" + (d.getMonth() + 1)).slice(-2); 
    let day = ("0" + d.getDate()).slice(-2);
    let hour = ("0" + d.getHours()).slice(-2);
    let minute = ("0" + d.getMinutes()).slice(-2);
    let second = ("0" + d.getSeconds()).slice(-2);
    return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  }

  return (
    <div className="container">
      <ToastContainer />
      <div className="row">
        <div style={{position: 'absolute',right:100}}>
          <Link to="/Chat">
            <button type="button" className="chatExitButton">
                Exit
            </button>
          </Link>
        </div>
        <div className="col">
          <div className="d-flex w-100 justify-content-between m-3">
            <div style={{color: "white", fontSize:30, fontWeight:1000, margin:(0,35,0,35)}}> 
              Enter some text and click reply button 
            </div>
          </div>
        </div>
      </div>
      {post.map((post) => (
      <div className="chat-singlePost" key={post.Timestamp} style={{display:'inline-block'}}>
        <div className="chat-replyTopBorder">
          <h2 style={{color: "black", fontSize:40}}>{post.Content}</h2>
        </div>
        <div className="mt-1">
          <div 
            className="d-flex w-100 justify-content-between"
            style={{ color: "#505050" }}>
            <div>
                <PersonCircle color="black" size={30} className="mr-2" />
                <span style={{color:"black",fontSize:30, position:'relative', left:20}}>Posted by {post.UserId}</span>
            </div>
            <p className="mb-1" style={{color: "black", fontSize:30}}>
                {convertTimeFormat(new Date(post.Timestamp))}
            </p>
          </div>
        </div>
      </div>
      ))}
      {
        replies.length === 0 &&
        <div className="container col-lg-6 shadow-lg p-3 mt-3 bg-body rounded">
            <h3 style={{fontSize:30}}>No reply for this question yet</h3>
        </div>
      }
      <div className="d-flex w-100 justify-content-between m-3">
        <div style={{color: "white", fontSize:30, fontWeight:1000, margin:(0,35,0,35)}}> 
          Showing {replies.length} replies
        </div>
      </div>
        {replies && replies.length > 0 &&
          replies.map((reply) => (
            <div key={reply.id} className="chat-postReply">
              <div className="ml-4">
                <PersonCircle color="black" size={30} className="mr-3" />
                <span style={{color:"black",fontSize:30, position:'relative', left:20}}>Posted by {reply.UserId}</span>
              </div>
              <div className="m-4" style={{fontSize:30,width:600,height:150,overflow:'auto',display:'inline-block'}}>
                {reply.Content}
              </div>
              <div className="d-flex w-100 justify-content-between mt-3 mb-3">
                <p className="mb-1" style={{color: "black", fontSize:30}}>
                    {convertTimeFormat(new Date(reply.Timestamp))}
                </p>
              </div>
            </div>  
          ))
        }
      <hr/>
      <br/>
      <PostReply id={QuestionId} />
    </div>
  );
};

export default PostDetail;
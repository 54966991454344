import { BDocs } from '../../ui-components'
import { useNavigate } from 'react-router-dom'
import logoImage from '../../Assets/Images/kioskLogo.png'
import wordImage from '../../Assets/Images/kioskWord.png'
import DownloadImage from '../../Assets/Images/DownloadIcon.png'
import FileImage from '../../Assets/Images/FileIcon.png'
import { Auth, Storage } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../Redux/userSlice'
import { useState } from 'react'

const DocScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isLoggedIn, profile } = useSelector(state => state.user);
    const [ showNoAccessDialog, setShowNoAccessDialog] = useState(false);

    const chat = () => {
      navigate('/Chat')
    }
    const exit = async() => {
      await Auth.signOut();
      dispatch(logout());
      navigate('/')
    }
    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    }

    const handleDownload = async (event) => {
      let fileName;
      switch(event.currentTarget.innerHTML){
        case "HIM Setup Guide":
          fileName = "HIM Setup Guide.pdf";
          break;
        case "HIM Technical Guide":
          fileName = "HIM Technical Guide.pdf";
          break;
        case "Development Client Reference Guide":
          fileName = "HIM Develop Client Reference.pdf";
          break;       
        default:
          break;            
      }
      try {
        if (profile === "Full") {          
          const result = await Storage.get(fileName,{download:true, validateObjectExistence:true});
          const url = URL.createObjectURL(result.Body);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName || 'download'; 
  
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
        else
          handleShowNoAccessDialog();
      } catch (error) {
        console.log(error);
      }
    };

    const handleShowNoAccessDialog = () => setShowNoAccessDialog(true);

    return (
      <div>
        {showNoAccessDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <h2>No access for downloading files!</h2>
              <button className='amplify-button' style={{margin: 20}} onClick={() =>setShowNoAccessDialog(false)}>OK</button>            
            </div>
          </div>    
        )}
      <BDocs className='BDocs'
      overrides={{
        "Logo": { src: logoImage},
        "Word": { src: wordImage},
        "Help" : { onClick: () => openInNewTab('https://vcc-beta.vsd-aws.compunetix.com/KIS/operators/en/Kiosk')},
        "Exit" : { onClick: () => exit()},
        "Document1":{ onClick:(event) => handleDownload(event)},
        "Document2":{ onClick:(event) => handleDownload(event)},
        "Document3":{ onClick:(event) => handleDownload(event)},
        "Download1":{ src: DownloadImage},
        "Download2":{ src: DownloadImage},
        "Download3":{ src: DownloadImage},
        "File1":{ src: FileImage},
        "File2":{ src: FileImage},
        "File3":{ src: FileImage},
        "FAQ":{ isDisabled : !isLoggedIn, onClick:() => chat()}
      }}/>
      </div>
    ) 
  }

export default DocScreen;
import { ASignUp } from '../../ui-components'
import { useNavigate } from 'react-router-dom'
import { Loader } from '@aws-amplify/ui-react'
import logoImage from '../../Assets/Images/kioskLogo.png'
import wordImage from '../../Assets/Images/kioskWord.png'
import { Auth } from 'aws-amplify'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { login } from '../../Redux/userSlice'
import { isPossiblePhoneNumber} from 'react-phone-number-input'

const SignUpScreen = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailMatch, setEmailMatch] = useState(true);
  const [canSignUp, setCanSignUp] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [goodPhoneNumber, setGoodPhoneNumber] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showUnConfirmDialog, setShowUnConfirmDialog] = useState(false);
  const [showUserExistDialog, setShowUserExistDialog] = useState(false);
  const [error, setError] = useState('');
  const [user, setUser] = useState();
  const [confirmCode, setConfirmCode] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();

    const handleConfirmDialog = () => {
      setShowDialog(false);
      setShowUnConfirmDialog(true);
    }
    const handleUnConfirmDialog = () => {
      setShowUnConfirmDialog(false);
    }

    const handleErrorDialog = () => setShowErrorDialog(!showErrorDialog);

    const handleUsernameInput = (event) => {
      setUsername(event.target.value);
      // Regular expression for validating username format
      const usernameRegex = /^[a-zA-Z0-9]{4,20}$/;
      const isValidUsername = usernameRegex.test(event.target.value);
      setIsValidUsername(isValidUsername);
    };

    const handleEmailInput = (event) => {
      setEmail(event.target.value);
      // Regular expression for validating email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(event.target.value);
      setIsValidEmail(isValidEmail);
    };

    const handlePasswordInput = (event) => {
      setPassword(event.target.value);
      const passwordRegex = /^[a-zA-Z0-9-!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,20}$/;
      const isValidPassword = passwordRegex.test(event.target.value);
      setIsValidPassword(isValidPassword);
    }

    const handlePhoneNumberInput = (value) => {
      const isPosssible = value && isPossiblePhoneNumber(value) ? true : false;
      setPhoneNumber(value === undefined? '' : value);
      setGoodPhoneNumber(isPosssible);
    }

    const handleConfirmPassword = (event) => {
      setConfirmPassword(event.target.value);
      setPasswordMatch(event.target.value === password)
    }

    const handleConfirmEmail = (event) => {
      setConfirmEmail(event.target.value)
      setEmailMatch(event.target.value === email)
    }

    const handleConfirmationCodeInput = (event) => {
      setConfirmCode(event.target.value)
    };

    const handleUserExistDialog = () => {
      setShowUserExistDialog(false);
    }

    // Update canSignUp state when any input changes
    useEffect(() => {
      if(email.length > 0 
        && username.length > 0 
        && password.length > 0
        && confirmPassword.length > 0
        && confirmEmail.length > 0
        && phoneNumber.length > 0
        && isValidEmail 
        && isValidUsername 
        && isValidPassword
        && passwordMatch
        && emailMatch
        && goodPhoneNumber)
          setCanSignUp(true);
        else
          setCanSignUp(false);
        
      document.querySelector('.PhoneInputInput').style.width = "1060px";
    }, [email, username, password, confirmPassword, confirmEmail, phoneNumber, goodPhoneNumber, isValidEmail, isValidUsername, isValidPassword, passwordMatch, emailMatch]);

    const navigate = useNavigate()
    const signUp = async() => {
      try {
        const { user } = await Auth.signUp({
          username: username,
          password: password,
          attributes: {
            email : email, // optional
            phone_number : phoneNumber// optional - E.164 number convention
            // other custom attributes
          },
          autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: true
          }
        });
        console.log(user);
        setUser(user);
        setShowDialog(true);
      } catch (error) {
        console.log('error signing up:', error);
        if (error.code === 'UsernameExistsException') {
          setError(error.message);
          setShowUserExistDialog(true);
        } else {
          setError(error.message);
          setShowErrorDialog(true);
        }
      }
    }

    const confirmSignUp = async() => {
      try {
        await Auth.confirmSignUp(user.username, confirmCode);
        dispatch(login({username}));
        navigate('/Product');
      } catch (error) {
        console.log('error', error);
        setError(error.message);
        setShowErrorDialog(true);
      }
    }

    const resendVerficationCode = async() => {
      try {
        setShowLoader(true);
        await Auth.resendSignUp(user.username);
      }
      catch(error){
        console.log(error);
      }finally{
        setShowLoader(false);
      }
    }

    const exit = () => {
      navigate('/')
    }
    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  
    return(
      <div>
        {showDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <button className="close-icon" style={{color:'#000000'}} onClick={handleConfirmDialog}>&#10005;</button>
              <h2>We Emailed You</h2>
              <p className='popup-bodyText'>To log in, enter the code we emailed to {email},<br/> it may take a minute to arrive.</p>
              <input className='amplify-input' placeholder='Enter your code here' onChange={handleConfirmationCodeInput} />
              <div style={{margin: 20}}>
                <button className='amplify-button' style={{position:'relative'}} onClick={confirmSignUp}>Confirm</button>
                <button className='amplify-button' style={{position:'relative', left:55 }} onClick={resendVerficationCode} disabled={showLoader}>
                  {showLoader && <Loader style={{margin: 15}} size='small' />}            
                  Resend Code
                </button>
              </div>
            </div>
          </div>
        )}
        {showErrorDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <h2>Sign up failed</h2>
              <p className='popup-bodyText'>{error}</p>
              <button className='amplify-button' style={{margin: 20}} onClick={handleErrorDialog}>OK</button>  
            </div>
          </div>
        )}
        {showUnConfirmDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <h2>Email not verified yet!</h2>
              <p className='popup-bodyText'>Username has been registered but not verified,</p>
              <p className='popup-bodyText'>Go to the login page and enter your username/password for verification</p>
              <button className='amplify-button' style={{margin: 20}} onClick={handleUnConfirmDialog}>No, not yet</button>  
              <button className='amplify-button' style={{margin: 20}} onClick={exit}>Yes, let's go</button>  
            </div>
          </div>
        )}
        {showUserExistDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <h2>Sign Up Failed!</h2>
              <p className='popup-bodyText'>User already exist or email has not been verified,</p>
              <p className='popup-bodyText'>Please go to the login page to finish verification or login process.</p>
              <button className='amplify-button' style={{margin: 20}} onClick={handleUserExistDialog}>No, not yet</button>  
              <button className='amplify-button' style={{margin: 20}} onClick={exit}>Yes, let's go</button>  
            </div>
          </div>
        )}
        <ASignUp className='ASignUp'
        overrides={{
          "Logo": { src: logoImage},
          "Word": { src: wordImage},
          "Username":{ onChange:(event) => handleUsernameInput(event), hasError:!isValidUsername, errorMessage:'Username length must be at least 4 to 20'},
          "EmailAddress":{onChange:(event) => handleEmailInput(event), hasError:!isValidEmail, errorMessage:'Email must be a right format,ex: example@example.com'},
          "Password":{onChange:(event) => handlePasswordInput(event), type:'password', hasError:!isValidPassword, errorMessage:'Password length must be at least 8 to 20'},
          "EnterPasswordAgain":{ onChange:(event) => handleConfirmPassword(event), type:'password', hasError:!passwordMatch, errorMessage:'Password does not match, Please enter a matching password'},
          "EnterEmailAgain":{onChange:(event) => handleConfirmEmail(event), hasError:!emailMatch, errorMessage:'Email does not match, Please enter a matching email'},
          "PhoneNumber":{onChange:(event) => handlePhoneNumberInput(event)},
          "PhoneNumberNotValidText":{display:(goodPhoneNumber?'none':'block')},
          "Help" : { onClick: () => openInNewTab('https://vcc-beta.vsd-aws.compunetix.com/KIS/operators/en/Kiosk')},
          "Login" : { isDisabled: !canSignUp, onClick: () => signUp()},
          "Exit" : { onClick: () => exit()},
        }}/>
      </div>
    )
  }

export default SignUpScreen;
import { BProducts } from '../../ui-components'
import { useNavigate } from 'react-router-dom'
import logoImage from '../../Assets/Images/kioskLogo.png'
import wordImage from '../../Assets/Images/kioskWord.png'
import DocImage from '../../Assets/Images/DocIcon.png'
import IotImage from '../../Assets/Images/IotIcon.png'
import HIMImage from '../../Assets/Images/HIMIcon.png'
import { Auth } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../Redux/userSlice'

const ProductsScreen = () => {
    const dispatch = useDispatch() 
    const navigate = useNavigate()
    const isLoggedIn = useSelector(state => state.user.isLoggedIn)

    const exit = async() => {
      await Auth.signOut();
      dispatch(logout());
      navigate('/')
    }
    const him = () => {
      navigate('/Him')
    }
    const doc = () => {
      navigate('/Doc')
    }
    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    }

    const chat = () => {
      navigate('/Chat')
    }
  
    return(
      <BProducts className='BProducts' 
      overrides={{
        "Logo": { src: logoImage},
        "Word": { src: wordImage},
        "Help" : { onClick: () => openInNewTab('https://vcc-beta.vsd-aws.compunetix.com/KIS/operators/en/Kiosk')},
        "Exit" : { onClick: () => exit()},
        "HIM" : { onClick: () => him()},
        "Iot" : { onClick: () => openInNewTab('https://uat.my-canopy.com/#/login')},
        "Doc" : { onClick: () => doc()},
        "HIMIcon":{ src: HIMImage},
        "IoTIcon":{ src: IotImage},
        "DocIcon":{ src: DocImage},
        "FAQ":{ isDisabled : !isLoggedIn, onClick:() => chat()}
      }}/>
    )
  }

export default ProductsScreen;